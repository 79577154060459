import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { getCookie, removeCookie } from 'typescript-cookie';
import { useThemeStore } from '@/stores/useThemeStore';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      path: '/products',
    },
  },
  // login
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "index" */ '@/views/login/index.vue'),
    meta: {
      layout: 'LoginLayout',
    },
  },
  // mannequins
  {
    path: '/mannequins',
    name: 'mannequins',
    component: () => import(/* webpackChunkName: "mannequins" */ '@/views/mannequins/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Mannequins',
    },
  },
  {
    path: '/mannequins/add',
    name: 'mannequins-add',
    component: () => import(/* webpackChunkName: "mannequins-add" */ '@/views/mannequins/add/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Add new mannequin',
    },
  },
  // products
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '@/views/products/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Products',
    },
  },
  {
    path: '/products/add',
    name: 'products-add',
    component: () => import(/* webpackChunkName: "products-add" */ '@/views/products/add/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Add new product',
    },
  },
  {
    path: '/products/:ean',
    name: 'products-ean',
    component: () => import(/* webpackChunkName: "products-ean" */ '@/views/products/_ean/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Insert cart url',
    },
  },
  {
    path: '/products/combination',
    name: 'products-combination',
    component: () => import(/* webpackChunkName: "products-combination" */ '@/views/products/combination/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'New product combination',
    },
  },
  // product types
  {
    path: '/product-types',
    name: 'product-types',
    component: () => import(/* webpackChunkName: "product-types" */ '@/views/product-types/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Product Type',
    },
  },
  {
    path: '/product-types/add',
    name: 'product-types-add',
    component: () => import(/* webpackChunkName: "product-types-add" */ '@/views/product-types/add/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Add new product type',
    },
  },
  //import
  {
    path: '/import/product-model',
    name: 'import-product-model',
    component: () => import(/* webpackChunkName: "import-product-model" */ '@/views/import/product-model/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Import Product Model',
    },
  },
  {
    path: '/import/product-model-color',
    name: 'import-product-model-color',
    component: () => import(/* webpackChunkName: "import-product-model-color" */ '@/views/import/product-model-color/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Import Product Model Color',
    },
  },
  {
    path: '/import/sheet-file',
    name: 'import-sheet-file',
    component: () => import(/* webpackChunkName: "import-sheet-file" */ '@/views/import/sheet-file/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'Import Sheet File',
    },
  },
  {
    path: '/import/',
    name: 'import',
    component: () => import(/* webpackChunkName: "import" */ '@/views/import/index.vue'),
    meta: {
      layout: 'MainLayout',
      title: 'All products',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const accessToken = getCookie('user_access_token');
  const nextRoute = to.name?.toString() || '';
  const themeStore = useThemeStore();
  themeStore.closeSidebar();
  if (nextRoute === 'login') {
    if (accessToken && !isTokenExpired()) {
      window.location.href = '/mannequins';
    } else {
      removeCookieSession();
      next();
    }
  } else {
    if (accessToken && !isTokenExpired()) {
      next();
    } else {
      removeCookieSession();
      window.location.href = '/login';
    }
  }
});

function isTokenExpired () {
  const accessTokenExpiryTime = getCookie('session_expiry_time');
  const currentTime = new Date();
  let expiryTime = new Date(currentTime);
  if (accessTokenExpiryTime) {
    expiryTime = new Date(Number(accessTokenExpiryTime));
    if (currentTime.getTime() >= expiryTime.getTime()) {
      return true;
    }
    return false;
  } else {
    return true;
  }
}
function removeCookieSession () {
  removeCookie('user_access_token');
  removeCookie('session_expiry_time');
  // removeCookie('user_token_expire_time');
}

export default router;
