import { defineStore } from 'pinia';

export const useThemeStore = defineStore('themeController', {
  state: () => ({
    sidebarIsActive: false,
  }),
  actions: {
    openSidebar() {
      this.sidebarIsActive = true;
    },
    closeSidebar() {
      this.sidebarIsActive = false;
    },
    toggleSidebar() {
      this.sidebarIsActive = !this.sidebarIsActive;
    },
  },
});
